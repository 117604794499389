import React from 'react';
import "./ProduktCta.css";

function ProduktCta() {
    return (
        <div className="produkt_cta">
            <div className="produkt_cta_container">
            <div className="produkt_cta_links">
            <img src="../img/Handgemacht_1.jpg" alt="Kartonagen Kontakt"/>
            </div> 
            <div className="produkt_cta_mitte">
            <p>Bei Anfragen jeder Art:</p>
            <h3>Kontaktieren Sie uns!</h3>
            <h4>Telefon <span>+43 1 493 35 35</span></h4>
            <h4>Email: <span>office@kartonagen-weber.at</span></h4>

            </div>
            <div className="produkt_cta_rechts">
            {/* <img src="./img/whatsapp_logo.svg" alt="Telephone Logo"/> */}
            </div>
            </div>
        </div>
    )
}

export default ProduktCta
