import React, { useState } from 'react';
import './ProduktKontakt.css';
import emailjs from 'emailjs-com';

function KontaktForm({submitForm, ProduktName}) {

    const [Submitted, setSubmitted] = useState(false)
    
      function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm( 'service_jixa5pm', 'template_yv6de9b', e.target, 'user_Vj1X1aY6fIOlEKY6msVA9')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          setSubmitted(true);
          submitForm();

    
      }
    
      return (
   
            <div className="formular">
                
              <form
                onSubmit={sendEmail}
                id="contact-form"
                name="contact-form"
              >
                <input
                  className="input"
                  placeholder="Vor & Nachname*"
                  name="name"
                  type="text"
                  required
                />
                <input
                  className="input"
                  placeholder="E-Mail-Adresse*"
                  type="email"
                  name="email"
                  required
                />
               
                 <input
                  className="input"
                  placeholder="Telefonnummer"
                  name="stk"
                  type="text"
                />
                
                <textarea
                className="input5"
                placeholder="Wie können wir Ihnen weiterhelfen?"
                type="text"
                name="message"
                />
 <select
              className="input_6"
              name="produkt"
              required
              value={ProduktName}
            >
              <option value={ProduktName}></option>
            
            </select>
             


    
    
                <div className="checkbox_container">
                  <input
                    type="checkbox"
                    id="kontakt"
                    name="kontakt"
                    required
                  />
                  <label for="kontakt">
                    Ich akzeptiere die Datenschutzbestimmungen
                  </label>
                </div>
                <button type="submit" value="send">Absenden</button>
              </form>
            </div>
      );
    }
    
    export default KontaktForm;