import React, {useState} from 'react';
import "./HomeProducts.css";
import HomeProduct from "./HomeProduct.js"

function HomeProducts() {

    const [toggle, setToggle] = useState(true);
    const toggler = () => {
        setToggle(!toggle) 
    }

    return (
        <div className="home_products">
            <h3>Unsere beliebtesten Produkte</h3>
            <hr />
            <div className="home_product_row">
            <HomeProduct
            src="./img/Rohkartons.jpg"
            alt="Rohkartons" 
            description="Rohe Kartonagen"
            url="/Rohe-Kartonagen"/>
               <HomeProduct
            src="./img/Ueberzogene_Kartons.jpg"
            alt="Überzogene" 
            description="Überzogene Kartons"
            url="/Überzogene-Kartonagen"/>
               <HomeProduct
            src="./img/Luxuskartonagen1.jpg"
            alt="Luxuskartonagen" 
            description="Luxuskartonagen"
            url="/Luxuskartonagen"/>
               <HomeProduct
            src="./img/Diplomrollen.jpg"
            alt="Diplomrollen" 
            description="Diplomrollen"
            url="/Diplomrollen"/>
           
            </div>
         
            <div className={toggle ? "home_product_row" : "home_product_none"}>
            <HomeProduct
            src="./img/Klarsicht.jpg"
            alt="Klarsicht_Verpackungen" 
            description="Klarsicht-Verpackungen"
            url="/Klarsichtpackungen"/>
               <HomeProduct
            src="./img/Aktenkartons.jpg"
            alt="Aktenkartons" 
            description="Aktenkartons"
            url="/Aktenkartons"/>
               <HomeProduct
            src="./img/Schulschachteln.jpg"
            alt="Schulschachteln Peter Weber" 
            description="Schulschachteln"
            url="Schulschachteln"/>
               <HomeProduct
            src="./img/Teebaeckerei-Kartons.jpg"
            alt="Teebäckerei-Kartons" 
            description="Konfekt Kartons"
            url="Konfekt-Kartons"/>
            </div>

            <div className={toggle? "home_product_button1" : "home_product_button_none"}>
            <button onClick = {toggler} >Weniger Ansehen</button>
            </div>
            <div className={toggle? "home_product_button_none" : "home_product_button1"}>
            <button onClick = {toggler} >Mehr Ansehen</button>
            </div>
       
        </div>
    )
}

export default HomeProducts
