import React from 'react';
import "./Home.css";
import HomeHeader from "./HomeHeader.js";
import HomeProducts from "./HomeProducts.js";
import HomeInfo1 from "./HomeInfo1.js";
import HomeFeatures from "./HomeFeatures.js";
import HomeFeatures2 from "./HomeFeatures2.js";
import HomeReferenzen from "./HomeReferenzen.js";
import HomeSlice from "./HomeSlice.js";
import {Helmet} from "react-helmet"
import ChristmasBanner from './ ChristmasBanner';


function Home() {
    return (
        <div className="home">
               <Helmet>
            <title>Kartonagen aus Österreich - Traditionsbetrieb seit 1895 </title>
            <meta name="description" content="Willkommen bei Peter Weber Kartonagen - Ein Traditionsbetrieb aus Wien. Seit 1895 stellen wir individuelle Verpackungen, Kartonagen und Stanzprodukte her. Senden Sie uns heute noch eine Anfrage." />
        </Helmet>
        <ChristmasBanner />
            <HomeHeader />
            <HomeProducts />
            <HomeInfo1 
            img="./img/Peter_Weber.jpg"
            title="Ein Familienbetrieb seit über 125 Jahren."
            textbig="Ein Traditionsbetrieb mit Wiener Geschichte."
            text="Die Ursprünge unserer Firma für die Kartonagenproduktion gehen bis ins Jahr 1895 zurück. Auch heute ist unser familiengeführter Betrieb ein Garant für hohe Qualität und Zuverlässigkeit."
            button="Anfrage Senden"
            url="/kontakt"
            />
            <HomeFeatures />
            <HomeFeatures2 />
            <HomeSlice />
            <HomeReferenzen />
           
        </div>
    )
}

export default Home
