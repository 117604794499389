import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Rohkartons() {
    return (
        <div className="produkt_info">
                 <Helmet>
            <title>Rohkartons - Peter Weber Kartonagen</title>
            <meta name="description" content="Der „Klassiker“ –bestehend aus Unterteil mit Deckel-, hergestellt aus Karton, Vollpappe oder Wellpappe, jeweils in verschiedenen Größen und Materialstärken erhältlich. Die Benützung erfolgt in den verschiedensten Bereichen (z.B. Sandwich, Kerzen, Lagerstellagenordnung, Archivablage, elektronische Bauteile, etc.)." />
        </Helmet>
            <div className="produkt_page">
 <h1>Rohe Kartonagen</h1>
<hr />
 <p>Der „Klassiker“ –bestehend aus Unterteil mit Deckel-, hergestellt aus Karton, Vollpappe oder Wellpappe, jeweils in verschiedenen Größen und Materialstärken erhältlich. Die Benützung erfolgt in den verschiedensten Bereichen (z.B. Sandwich, Kerzen, Lagerstellagenordnung, Archivablage, elektronische Bauteile, etc.). </p>

 <ProduktInfo 
info= "Handgemacht in 1100 Wien"
/>


<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>





<div className="produkt_fotos">
<img src="./img/Rohkartons_3.jpg" alt="info" />
<img src="./img/Rohkartons_2.jpg" alt="info" />

</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Rohkartons" />
            </div>

        </div>
    )
}

export default Rohkartons
