import React from 'react';
import {Link} from "react-router-dom";
import "./Footer.css";

function Footer() {


    return (
        <div className="footer">
            <div className="footer_elements">
            <img src="./img/Peter_Weber_Kartonagen_Logo.png" alt=""/>
            <p>Peter Weber Kartonagen 1895 - 2021</p>
             <Link className="link_agb" to="/impressum">Impressum</Link> 
             <Link className="link_agb" to="/impressum">Datenschutz</Link> 
             <Link className="link_agb" to="/kontakt">Kontakt</Link> 
             </div>
        </div>
    )
}

export default Footer
