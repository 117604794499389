import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Ueber.css";




const SwiperSlide = () => {
  return (
    <Carousel
    showThumbs={false}
    showIndicators={false}
    showStatus={false}
    infiniteLoop={true}
    autoPlay={true}
    className="swiper_carousel"

    >
     <div>
        <img src="./img/Peter_Weber_Wien.jpg" alt="Peter Weber"/>
    </div>
    <div>
        <img src="./img/Heidelberg_Druckmaschine.jpg" alt="Heidelberg"/>
    </div>
    <div>
        <img src="./img/Weber_Maschine.jpg" alt="Peter Weber Druckmaschine"/>
    </div>
    <div>
        <img src="./img/Heidelberg_Cylinder.jpg"alt="Traditionelle Druckerei"/>
    </div>
    <div>
        <img src="./img/Kartonagen_1100.jpg" alt="Kartonherstellung"/>
    </div>
    <div>
        <img src="./img/Heidelberg_Original.jpg" alt="Original Heidelberg Cylinder"/>
    </div>
    <div>
        <img src="./img/Handgemacht_3.jpg" alt="Handgefertigt"/>
    </div>
    <div>
        <img src="./img/Demel_Soehne.jpg" alt="Demel_und_Söhne"/>
    </div>

  
  
</Carousel>
  );
}

export default SwiperSlide
