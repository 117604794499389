import React, { useState } from 'react';
import './ProduktKontakt.css';
import KontaktForm_2 from "./KontaktForm_2.js";
import KontaktFormSuccess from './KontaktFormSuccess';




function ProduktKontakt() {
    const [Submitted, setSubmitted] = useState(false)

function submitForm() {
  setSubmitted(true);
}

  return (
    <div className="kontakt_alternative">
        <div className= {!Submitted? "produkt_kontakt" : "produkt_hidden"}>

            <h4>Kontaktformular</h4>
            <p>Hier können Sie Anfragen senden, welche daraufhin direkt von unserem Team bearbeitet werden</p>
        </div>
       {!Submitted ? <KontaktForm_2 submitForm={submitForm} /> : <KontaktFormSuccess /> }
       </div>
  );
}

export default ProduktKontakt;
