import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";

function Produkt() {
    return (
        <div className="produkt_anfrage">
            <div className="produkt_page">
                
 <h1>Luxuskartonagen</h1>
<hr />
 <p>Bei unseren Luxus Kartonagen sind ihrer Kreativität kennen keine Grenzen gesetzt. Wir haben bereits für namenhafte Marken Edle Kartonagen hergestelltdessen Qualität seinesgleichen sucht. </p>

 <ProduktInfo 
info= "Handgemacht in 1100 Wien"
/>

<ProduktInfo 
info= "Bestellung ab 100 Stk"
/>


<ProduktInfo 
info= "Verschiedene Konstruktionen Möglich"
/>



<div className="produkt_fotos">
<img src="./img/Luxuskartonagen.jpg" alt="info" />
 <img src="./img/Peter_Weber_Kartonagen.jpg" alt="info" />

</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt />
            </div>

        </div>
    )
}

export default Produkt
