import './App.css';
import Home from "./Home.js";
import Header from "./Header.js";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from "./Footer/Footer.js";
import Produkt from "./Produkte/Produkt.js";
import Ueber from "./UeberUns/Ueber.js";
import Kontakt from "./Kontakt/Kontakt.js";
import Luxuskartonagen from './Produkte/Luxuskartonagen';
import Diplomrollen from './Produkte/Diplomrollen';
import Rohkartons from './Produkte/Rohkartons';
import Schulschachteln from './Produkte/Schulschachteln';
import Stanzprodukte from './Produkte/Stanzprodukte';
import Spulen from './Produkte/Spulen';
import Faltkartons from './Produkte/Faltkartons';
import Aktenkartons from './Produkte/Aktenkartons';
import Klarsichtpackungen from './Produkte/Klarsichtpackungen';
import Ueberzogene from './Produkte/Ueberzogene';
import Teebäckerei from './Produkte/Teebäckerei';
import ScrollToTop from './ScrollToTop';
import TopPic from "./TopPic";
import Agb from './Agb';









function App() {
  return (
    <div className="App">

      <Router>
      <ScrollToTop />
        <Switch>
        <Route path="/produkt">
          <Header />
         <Produkt />
        </Route>
        <Route path="/Luxuskartonagen">
          <Header />
         <Luxuskartonagen />
         <Footer />
        </Route>
        <Route path="/Aktenkartons">
          <Header />
         <Aktenkartons />
         <Footer />
        </Route>
        <Route path="/Konfekt-Kartons">
          <Header />
         <Teebäckerei />
         <Footer />
        </Route>
        <Route path="/Überzogene-Kartonagen">
          <Header />
         <Ueberzogene />
         <Footer />
        </Route>
        <Route path="/Klarsichtpackungen">
          <Header />
         <Klarsichtpackungen />
         <Footer />
        </Route>
        <Route path="/Schulschachteln">
          <Header />
         <Schulschachteln />
         <Footer />
        </Route>
        <Route path="/Faltkarton">
          <Header />
         <Faltkartons />
         <Footer />
        </Route>
        <Route path="/Spulen">
          <Header />
         <Spulen />
         <Footer />
        </Route>
        <Route path="/Stanzprodukte">
          <Header />
         <Stanzprodukte />
         <Footer />
        </Route>
        <Route path="/Rohe-Kartonagen">
          <Header />
         <Rohkartons />
         <Footer />
        </Route>
        <Route path="/Diplomrollen">
          <Header />
         <Diplomrollen />
         <Footer />
        </Route>
        <Route path="/impressum">
          <Header />
         <Agb />
         <Footer />
        </Route>
        <Route path="/über">
          <Header />
         <Ueber />
         <Footer />
        </Route>
        <Route path="/kontakt">
          <Header />
          <TopPic />
         <Kontakt />
         <Footer />
        </Route>
        <Route path="/">
          <Header />
        <Home /> 
        <Footer />
        </Route>
      
        </Switch>
      </Router>
    </div>
  );
}

export default App;
