import React from 'react';
import "./HomeFeatures.css";

function HomeFeatures() {
    return (
        <div className="home_features">

            <div className="home_features_top">
            <h2>Nachhaltig Verpacken mit Kartonagen von Peter Weber</h2>
            <hr />
            <p> Karton bietet Ihnen eine umweltfreundliche Alternative zu Plastik oder anderen Polyethylenverpackungen. Unsere hochwertigen Kartonagen werden lokal produziert und in höchster Qualität gefertigt. Ein Handwerk das seit über 100 Jahren weitergegeben und stetig verbessert wurde. </p>
            </div>

            <div className="home_features_bottom">

            <div className="home_feature">
            <img src="./img/palace.svg" alt="palace"/>
            <h3>Hergestellt direkt in Wien</h3>
            <p>Seit über 100 Jahren stammen unsere Kartonagen aus Wien. Unsere neue Produktionshalle befindet sich in Wien Favoriten.</p>
            </div>

            <div className="home_feature">
            <img src="./img/bio.svg" alt="bio"/>
            <h3>Biologisch abbaubar</h3>
            <p>Karton ist ein umweltfreundliches Material welches biologisch abbaubar ist. So verpacken Sie ohne schlechtem Gewissen und in Top-Qualität.  </p>
            </div>

            <div className="home_feature">
            <img src="./img/versand.svg" alt="versand"/>
            <h3>Kurze Lieferketten</h3>
            <p>Sparen Sie sich unnötige Wartezeiten. Bei uns können Sie Ihre Ware direkt vor Ort abholen oder von unserem Team zustellen lassen.</p>
            </div>
            </div>
        </div>
    )
}

export default HomeFeatures
