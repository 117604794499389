import React from 'react';
import {Link} from "react-router-dom";
import "./HomeInfo1.css";

function HomeInfo1({toptitle, title, textbig, text, button,url, img, alt}) {
    return (
        <div className="home_info_1">
            <div className="home_info_container_1">
            <div className="home_info_left_1">
            <img src={img} alt={alt}/>
        </div>
        <div className="home_info_right_1">
            <div className="home_info_right_neu">
            <p>{toptitle}</p>
                </div>
        <h2>{title}</h2>
        <h4>{textbig}</h4>
        <p>{text}</p>
        <Link className="link_button" to={url}>{button}</Link> 
        </div>
            </div>
        </div>
    )
}

export default HomeInfo1
