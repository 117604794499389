import React from 'react';
import "./Produkt.css";
import ProduktKontakt_3 from "./ProduktKontakt_3.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Schulschachteln() {
    return (
        <div className="produkt_info">
              <Helmet>
            <title>Schulschachteln - Peter Weber Kartonagen</title>
            <meta name="description" content="In verschiedenen Größen und Ausführungen erhältlich. Stülpdeckelschachteln aus Wellpappe in Weiss oder Stülpdeckelschachteln aus Graukarton mit Deckel aus Karton oder Klarsichtmaterial. A3, A4, A5, A6." />
        </Helmet>
            <div className="produkt_page">
 <h1>Schulschachteln</h1>
<hr />
 <p>Stülpdeckelschachteln aus Wellpappe in Weiss oder Stülpdeckelschachteln aus Graukarton mit Deckel aus Karton oder Klarsichtmaterial.</p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>


<ProduktInfo 
info= "Online Anfrage senden"
/>





<div className="produkt_fotos">
<img src="./img/Schulschachteln_2.jpg" alt="info" />


</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt_3
            Produkt="Schulschachteln" />
            </div>

        </div>
    )
}

export default Schulschachteln
