import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Diplomrollen() {
    return (
        <div className="produkt_info">
                   <Helmet>
            <title>Diplomrollen - Peter Weber Kartonagen </title>
            <meta name="description" content="Wir fertigen Diplomrollen nach ihren Wünschen an. Verschiedene Farben, Größen und unterschiedliche Materialien verfügbar." />
        </Helmet>
            <div className="produkt_page">
 <h1>Diplomrollen</h1>
<hr />
 <p>Bei unseren Diplomrollen sind ihrer Kreativität keine Grenzen gesetzt. Wir haben bereits für namhafte Marken Edle Kartonagen hergestellt, dessen Qualität seinesgleichen sucht. Unsere Diplomrollen sind in verschiedenen Durchmessern und Höhen erhältlich. </p>

 <ProduktInfo 
info= "Handgemacht in 1100 Wien"
/>

<ProduktInfo 
info= "Logodruck optional möglich"
/>


<ProduktInfo 
info= "Verschiedene Farben verfügbar"
/>

<ProduktInfo 
info= "Silber- und Goldfolienveredelung"
/>




<div className="produkt_fotos">
<img src="./img/Diplomrollen4.jpg" alt="info" />
<img src="./img/Diplomrollen_Farben.jpg" alt="info" />
 <img src="./img/Diplomrollen2.jpg" alt="info" />

</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Diplomrollen" />
            </div>

        </div>
    )
}

export default Diplomrollen
