import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Teebäckerei() {
    return (
        <div className="produkt_info">
               <Helmet>
            <title>Konfekt Kartons - Peter Weber Kartonagen</title>
            <meta name="description" content="Wir stellen Verpackungen für Bäckereien, Konditoreien und Süßwaren her. Besonders in der Weihnachtszeit sind diese handgefertigten Kartons aus Alu-Foodline oder mit verschiedenen Papiermotiven sehr gefragt! Verschiedene Größen erhältlich." />
        </Helmet>
            <div className="produkt_page">
 <h1>Konfekt-Kartons</h1>
<hr />
 <p>Besonders in der Weihnachtszeit sind diese handgefertigten Kartons aus Alu-Foodline oder mit verschiedenen Papiermotiven sehr gefragt!</p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>

<ProduktInfo 
info= "Individuell Personalisierbar"
/>


<div className="produkt_fotos">
<img src="./img/Teebaeckerei-Kartons_2.jpg" alt="Teebäckerei Kartonagen" />
<img src="./img/Teebaeckerei_Kartons_3.jpg" alt="Teebäckerei Detail" />
<img src="./img/Luxuskartonagen1.jpg" alt="Teebäckerei Detail" />
<img src="./img/Altmann_Kuehne.jpg" alt="Altmann und Kühne" />





</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Konfekt-Kartons" />
            </div>

        </div>
    )
}

export default Teebäckerei
