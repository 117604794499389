import React from 'react';
import "./KontaktFormSuccess.css";

function KontaktFormSuccess() {
    return (
        <div className="kontakt_success">

            <div className="kontakt_success_text">
            <h3>
                Vielen Dank für Ihre Anfrage! 
            </h3>
            <p>Wir melden uns in kürze bei Ihnen!</p>
            </div>

            <div className="kontakt_success_img">
            <img src="./img/Peter_Weber.jpg" alt="Inge Nopp" />
            </div>
        
        </div>
    )
}

export default KontaktFormSuccess
