import React from 'react';
import {Link} from "react-router-dom";
import "./HomeHeader.css";

function HomeHeader() {
    return (
        <div className="weber_header">
             <img id ="desktop_pic" src="./img/Peter_Weber_Kartonagen.jpg" alt=""/>
             <img id="mobile_pic" src="./img/Peter_Weber_Kartonagen_3.jpg" alt=""/>

            <div className="weber_textbar">
                <h1>
                Kartonagen & Verpackungen <br /> aus Wien seit 1895.
                </h1>
                <p>Peter Weber Kartonagen GmbH - Der Traditionsbetrieb in 1100 Wien</p> 
                <Link className="button_link_header" to="/kontakt">Anfrage Senden</Link>
            </div>
        </div>
    )
}
export default HomeHeader
