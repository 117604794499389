import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Aktenkartons() {



    return (
        <div className="produkt_info">
                       <Helmet>
            <title>Aktenkartons - Peter Weber Kartonagen</title>
            <meta name="description" content="Premium Kartonagen in Klein- und Großauflagen. Aufklapbar und in verschiedenen Formen verfügbar, sind diese Aktenkartons die ideale Ablage für wichtige Dokumente." />
        </Helmet>
            <div className="produkt_page">
 <h1>Aktenkartons</h1>
<hr />
 <p>Aufklappbar und in verschiedenen Formen verfügbar, sind diese Ordner die ideale Ablage für wichtige Dokumente.</p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>



<div className="produkt_fotos">
<img src="./img/Aktenkartons_2.jpg" alt="Aktenkartons" />


</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Aktenkartons" />
            </div>

        </div>
    )
}

export default Aktenkartons
