import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Klarsichtpackungen() {
    return (
        <div className="produkt_info">
        <Helmet>
            <title>Klarsichtpackungen - Peter Weber Kartonagen</title>
            <meta name="description" content="Aus PVC-Folie hergestellt, als Vollklarsichtschachtel (z.B. für Visitenkarten) oder nur als Deckel auf einem Kartonunterteil (z.B. für Torten) ermöglichen sie einen direkten Blick auf das darin verpackte Produkt." />
        </Helmet>
            <div className="produkt_page">
 <h1>Klarsichtverpackung & Teebäckerei</h1>
<hr />
 <p>Aus PVC-Folie hergestellt, als Vollklarsichtschachtel (z.B. für Visitenkarten) oder nur als Deckel auf einem Kartonunterteil (z.B. für Torten) ermöglichen diese einen direkten Blick auf das darin verpackte Produkt.</p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>



<div className="produkt_fotos">
<img src="./img/Klarsicht_2.jpg" alt="Klarsicht" />
<img src="./img/Klarsicht_3.jpg" alt="Klarsicht" />



</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Klarsichtpackung" />
            </div>

        </div>
    )
}

export default Klarsichtpackungen
