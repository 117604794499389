import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Stanzprodukte() {
    return (
        <div className="produkt_info">
            <Helmet>
            <title>Stanzprodukte - Peter Weber Kartonagen</title>
            <meta name="description" content="Auch auf beigestelltem Material bis zu einer Zuschnittgröße von 120x80cm möglich. Vielfalt je nach Kundenwunsch ist auch in diesem Bereich oberstes Gebot." />
        </Helmet>
            <div className="produkt_page">
 <h1>Stanzprodukte</h1>
<hr />
 <p>Auch auf beigestelltem Material bis zu einer Zuschnittgröße von 120x80cm möglich. Vielfalt je nach Kundenwunsch ist auch in diesem Bereich oberstes Gebot. </p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>




<div className="produkt_fotos">
<img src="./img/Stanzprodukte.jpg" alt="Stanz_Kartonagen" />


</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Stanzprodukte" />
            </div>

        </div>
    )
}

export default Stanzprodukte
