import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import "./Header.css";
import { useLocation } from 'react-router-dom';


function Header() {




const history = useHistory();



// custom hook to get the current pathname in React

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const isProductActive = () => {

    const path = usePathname
    
    const activeElements = ['/Luxuskartonagen', '/Rohe-Kartonagen', '/Überzogene-Kartonagen', '/Diplomrollen', '/Klarsichtpackungen', '/Aktenkartons', , '/Schulschachteln', '/Konfekt-Kartons', '/Spulen', '/Faltkarton'].filter(el => el === path())

    return activeElements.length > 0
} 



const [showMenu, setShowMenu] = useState(false)

let menu 

if(showMenu){
menu =    <div className="mobile_nav">
<div className="mobile_nav_header">
<img src="./img/cancel-1.svg" alt="cancel-icon" onClick={() => setShowMenu(!showMenu)}/>
</div>

<div className="mobile_nav_kategorie">
<h4>Allgemein</h4>
<hr/>
</div>


<div className="mobile_navigation"  onClick={() => setShowMenu(!showMenu)}>
<nav>
<Link className="nav_styling" to="/über">Über Uns</Link> 
<Link className="nav_styling" to="/kontakt">Kontakt</Link> 
</nav>
</div>


<div className="mobile_nav_kategorie">
<h4>Unsere Produkte</h4>
<hr/>
</div>
<div className="mobile_navigation"  onClick={() => setShowMenu(!showMenu)}>
<nav>
<Link className="nav_styling" to="/Luxuskartonagen">Luxuskartonagen</Link> 
    <Link className="nav_styling" to="/Rohe-Kartonagen">Rohkarton</Link> 
    <Link className="nav_styling" to="/Überzogene-Kartonagen">Überzogene </Link> 
     <Link className="nav_styling" to="/Diplomrollen">Diplomrollen</Link> 
     <Link className="nav_styling" to="/Klarsichtpackungen">Klarsicht</Link> 
     <Link className="nav_styling" to="/Aktenkartons">Aktenkartons</Link> 
     <Link className="nav_styling" to="/Schulschachteln">Schulschachteln</Link> 
     <Link className="nav_styling" to="/Konfekt-Kartons">Konfekt Kartons</Link> 
     <Link className="nav_styling" to="/Spulen">Spulen</Link> 
     <Link className="nav_styling" to="/Faltkarton">Faltkarton</Link> 







</nav>
</div>
</div>
}





    return (
        <div className="header">
           {menu}
            <div className="header_bar">

             <Link to="/">
                 <div className="header_logo">
                
                         <img src="./img/Peter_Weber_Kartonagen_Logo.png" alt=""/>
                </div>
                </Link>
                <Link to="/">
                 <div className="header_logo2">
                
                         <img src="./img/Peter_Weber_Kartonagen_Logo.png" alt=""/>
                </div>
                </Link>
                
                  <div className="header_left" >
                  <Link className={`link_button_service_2 ${usePathname() === "/" ? 'active' : '' }`} to="/">Home</Link>

                                          
                        <div className="header_parent_wrapper">
                        <h4 className={`produkt_header_schrift ${isProductActive() ? 'active' : '' }`}>Produkte</h4>

                        <div className="dropdown_menu">
                    <nav>
    <Link className="link_button_2" to="/Luxuskartonagen">Luxuskartonagen</Link> 
    <Link className="link_button_2" to="/Rohe-Kartonagen">Rohkarton</Link> 
    <Link className="link_button_2" to="/Überzogene-Kartonagen">Überzogene</Link> 
     <Link className="link_button_2" to="/Diplomrollen">Diplomrollen</Link> 
     <Link className="link_button_2" to="/Klarsichtpackungen">Klarsicht</Link> 
     <Link className="link_button_2" to="/Aktenkartons">Aktenkartons</Link> 
     <Link className="link_button_2" to="/Schulschachteln">Schulschachteln</Link> 
     <Link className="link_button_2" to="/Konfekt-Kartons">Konfekt Kartons</Link> 
     <Link className="link_button_2" to="/Spulen">Spulen</Link> 
     <Link className="link_button_2" to="/Faltkarton">Faltkarton</Link> 
                    </nav>
                       </div>
                       
                        </div>
                        <Link className={`link_button_service ${usePathname() === "/über" ? 'active' : '' }`} to="/über">Über Uns</Link>
                        <Link id="kontakt_button"className={`link_button_service ${usePathname() === "/kontakt" ? 'active' : '' }`} to="/kontakt">Kontakt</Link>     
                 </div>


              <div className="header_mobile">
                      <div className="header_mobile_right">
                              <div className="header_mobile_button" onClick={() => history.push("/kontakt")}>
                                     <h4>Kontakt</h4>
                                       <img src="./img/chat_icon.svg" alt="Chat-Icon"/>
                                 </div>
            <div className="header_mobile_menu">
                      <img src="./img/menu-icon.svg" alt="menu-icon" onClick={() => setShowMenu(!showMenu)}/>
                     
            </div>
            </div>
        </div>
        </div>            

        </div>


    )
}



export default Header;
