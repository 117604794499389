import React from 'react';
import "./HomeReferenzen.css";

function HomeReferenzen() {
    return (
        <div className="home_referenzen">
            <div className="home_referenzen_text">
            <p>Einige unserer zufriedenen Kunden - Wiener Traditionsbetriebe setzen auf uns:</p>
            </div>
            <div className="home_referenzen_fotos">
            <div className="home_referenzen_foto">
            <img src="./img/Groissboeck_Logo.png" alt="groissböck" />
            </div>
            <div className="home_referenzen_foto">
            <img src="./img/Leschanz_Logo.jpg" alt="Leschanz" />
            </div>
            <div className="home_referenzen_foto">
            <img src="./img/Demel_Logo.png" alt="Demel" />
            </div>
            <div className="home_referenzen_foto">
            <img src="./img/Fabiani_Logo.jpg" alt="Fabiani" />
            </div>
            <div className="home_referenzen_foto">
            <img src="./img/Huebler_Logo.png" alt="Hübler" />
            </div>
            </div>
        </div>
    )
}

export default HomeReferenzen
