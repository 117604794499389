import React from 'react';
import "./Kontakt.css";
import ProduktKontakt_2 from "../Produkte/ProduktKontakt_2.js";
import {Helmet} from "react-helmet";

function Kontakt() {
    return (

        
        <div className="produkt_information">
              <Helmet>
            <title>Kontakt - Peter Weber Kartonagen</title>
            <meta name="description" content="Unsere neue Firmenadresse ist: Himbergerstraße 2 1100 Wien. Besuchen Sie uns vor Ort oder senden Sie eine Anfrage über unser Kontaktformular." />
        </Helmet>
        <div className="produkt_wrapper">

<div className="produkt_kontakt_2">
            <div className="produkt_kontakt_container">

           
            <div className="produkt_kontakt_mitte">
            <p>Unsere Adresse:</p>
            <h3>Himbergerstraße 2 <br/> <span>1100 Wien</span></h3>
           
            <h4>Öffnungszeiten</h4>
            <h4>Montag-Freitag: <span>07:00-15:00</span></h4>

            </div>
            <div className="produkt_kontakt_rechts">
            <img src="./img/Warehouse.svg" alt="Warehouse"/>
            </div>
            </div>
        </div>

        <div className="produkt_kontakt_2">
            <div className="produkt_kontakt_container">
           
            <div className="produkt_kontakt_mitte">
            <p>So erreichen sie uns:</p>
            <h3>Telefon & Email</h3>
            <h4>Telefon: <span>+43 1 493 35 35</span></h4>
            <h4>Email: <span>office@kartonagen-weber.at</span></h4>

            </div>
            <div className="produkt_kontakt_rechts">
            <img src="./img/Telephone.svg" alt="Telephone Logo"/>
            </div>
            </div>
         
        </div>

    
    

        </div>
        <div className="produkt_kontakt">
    
        <ProduktKontakt_2 />

        </div>

    </div>
    )
}

export default Kontakt
