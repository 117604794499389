import React from 'react';
import "./HomeSlice.css";
import {Link} from "react-router-dom";


function HomeSlice() {
    return (
        <div className="home_slice">
            <div className="home_slice_background">
               
                <div className="home_slice_text">
            <h2>Werfen Sie einen Blick hinter die Kulissen.</h2>
            <Link className="link_button" to="/über">Mehr Erfahren</Link> 
        </div>
        <div className="home_slice_pic">
            <img src="./img/Heidelberg_Maschine.jpg" alt="irgendwas" />
        </div>
            </div>
        </div>
    )
}

export default HomeSlice
