import React from 'react';
import "./HomeProduct.css";
import {Link} from "react-router-dom";


function HomeProduct({src, alt, description, url}) {
    return (
        <Link className="link" to={url}>
        <div className="home_product">
            <img src={src} alt={alt}/>
            <h2>{description}</h2>
            <Link className="link_button"to="/produkt"> <p> Weiter </p></Link>
        </div>
        </Link>
    )
}

export default HomeProduct
