import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Spulen() {
    return (
        <div className="produkt_info">
            <Helmet>
            <title>Spulen - Peter Weber Kartonagen</title>
            <meta name="description" content="Unsere Spulen aus Karton sind in verschiedenen Größen und Ausführungen erhältlich." />
        </Helmet>
            <div className="produkt_page">
 <h1>Spulen</h1>
<hr />
 <p>Unsere Spulen sind in verschiedenen Durchmessern und Längen erhältlich. Ideal für Gummibänder oder Geschenkbänder. </p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>


<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>





<div className="produkt_fotos">
<img src="./img/Spule.jpg" alt="info" />


</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Spulen" />
            </div>

        </div>
    )
}

export default Spulen
