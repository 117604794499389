import React from 'react';
import "./Produkt.css";


function ProduktInfo({info}) {
    return (
        <div className="produkt_info_feature">
        <img src="./img/Check_Sign.png" alt="check" />
        <p>{info}</p>
        </div>
    )
}

export default ProduktInfo;
