import React from 'react';
import "./Ueber.css";
import SwiperSlide from "./SwiperSlide.js";
import {Helmet} from "react-helmet";


function Ueber() {
    return (
        <div className="ueber_uns">
             <Helmet>
            <title>Über Uns - Peter Weber Kartonagen</title>
            <meta name="description" content="Peter Weber Kartonagen zählt heute – auch durch die ständige Erweiterung der Produktpalette – zu den größten gewerblichen Kartonagewarenerzeugern Österreichs." />
        </Helmet>
            <div className="ueber_uns_pic">
            <SwiperSlide />
            <img src="./img/Julius_Sambeth_Inge_Nopp.png" alt="Julius Sambeth und Inge Nopp"/>
            <div>     
        </div>

            </div>
            <div className="ueber_uns_text">
        <h1>Unsere Geschichte</h1>
        <h3>Erfahren Sie mehr über Peter Weber Kartonagen GmbH</h3>
        <br />
        <p>
       <span> Julius Sambeth</span>  gründet 1895 in 1160 Wien, in einem Teil des Hoftraktes des Hauses Fröbelgasse 3, eine kleine Kartonagenerzeugung. In den folgenden Jahrzehnten wird mit viel Fleiß das Unternehmen ausgebaut.  <span> Maria Sambeth </span> führt ab 1949 das Unternehmen, nach dem Tod ihres Gatten Richard (Sohn des Firmengründers), alleine weiter. Die Betriebsräumlichkeiten werden in den ersten Stock des Hoftraktes ausgedehnt.      </p>
        <br /> 
        <p>
       <span>Inge Nopp</span>, geb. Sambeth, übernimmt nach der Pensionierung ihrer Mutter gemeinsam mit ihrem Gatten Hermann am 1.7.1967 den Betrieb.
Zum Produktsortiment kommen jetzt neben rohen und überzogenen Kartonagen auch Klarsichtverpackungen und Wellpappekartons. Den dadurch entstandenen technischen Anforderungen wird durch den Kauf diverser neuer Maschinen Rechnung getragen.
Dem Ehepaar Nopp gelingt es in den Folgejahren durch viel Fleiß und großen persönlichen Einsatz verschiedene Konkurrenzbetriebe zu übernehmen und ein florierendes Unternehmen zu schaffen.        </p>
        <br /> 
        <p>
       <span> Peter Weber</span>, der Neffe von Frau Nopp, übernimmt am 1.1.1997 den Familienbetrieb, in dem er seit seiner Lehrzeit bereits viel Erfahrung gesammelt hat.
Durch sein großes Engagement kann er die Produktion erheblich ausweiten und zu Beginn des Jahres 2000 einen weiteren, zum Verkauf stehenden Kartonagenbetrieb erwerben. Herr Reiselhuber ging in den wohlverdienten Ruhestand.
Dem Standort Fröbelgasse 3, im Wiener Bezirk Ottakring blieb man bis 2019 treu. <br /> <br /> In der Folge werden, wie bereits unter der Betriebsführung seiner Tante, die Betriebsräumlichkeiten abermals erweitert und umgebaut, sodass nahezu das ganze Erdgeschoß und der erste Stock des Hoftraktes der Produktion zur Verfügung stehen.
2018 stand ein weiterer Kartonagenbetrieb in Wien 21 zum Verkauf. Mit der Übernahme der Firma Wiesmeier konnte das Sortiment erneut erweitert werden. <br /> <br />
2019 zog der Betrieb in moderne Produktionsräumlickeiten im 10. Wiener Bezirk um. Die neue Adresse lautet: Himberger Straße 2, 1100 Wien.
Zur Belegschaft zählen zurzeit ein Betriebsleiter, fünf Facharbeiter und sieben ArbeiterInnen.
Peter Weber Kartonagen GmbH zählt heute – auch durch die ständige Erweiterung der Produktpalette – zu den größten gewerblichen Kartonagewarenerzeugern Österreichs.        </p>
            </div>
        </div>
    )
}

export default Ueber;
