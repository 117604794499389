import React, { useState } from 'react';
import './ProduktKontakt.css';
import emailjs from 'emailjs-com';

function KontaktForm({submitForm}) {

    const [Submitted, setSubmitted] = useState(false)
    
      function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm( 'service_jixa5pm', 'template_xtz3i7g', e.target, 'user_Vj1X1aY6fIOlEKY6msVA9')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          setSubmitted(true);
          submitForm();

    
      }
    
      return (
   
            <div className="formular">
                
              <form
                onSubmit={sendEmail}
                id="contact-form"
                name="contact-form"
              >
                <input
                  className="input"
                  placeholder="Vor & Nachname*"
                  name="name"
                  type="text"
                  required
                />
                <input
                  className="input"
                  placeholder="E-Mail-Adresse*"
                  type="email"
                  name="email"
                  required
                />

                  <input
                  className="input"
                  placeholder="Telefonnummer*"
                  name="telefon"
                  type="text"
                  required
                />

                  <input
                  className="input"
                  placeholder="Adresse"
                  name="adresse"
                  type="text"
                  required
                />
                 <input
                  className="input"
                  placeholder="PLZ"
                  name="plz"
                  type="text"
                />

            <select
              className="input4"
              name="deckel"
              required
            >
              <option value="Karton"> Karton-Deckel </option>
              <option value="Klarsicht"> Klarsichtmaterial </option>
              <option value="Kein Deckel"> Kein Deckel </option>

            </select>

            <select
              className="input4"
              name="size"
              required
            >
              <option value="A3"> DIN A3 (430x310x50/50mm) </option>
              <option value="A4"> DIN A4 (310x235x50/50mm) </option>
              <option value="A5"> DIN A5 (220x160x50/50mm) </option>
              <option value="A6"> DIN A6 (155x110x50/50mm) </option>
              <option value="A7"> DIN A7 (105x075x25/25mm) </option>

            </select>

            <select
              className="input4"
              name="art"
              required
            >
              <option value="Graukarton"> Graukarton (Geritzt, Eckengeheftet) </option>
              <option value="Wellpappe Weiss"> Wellpappe Weiss (Gestanzt, Flach, Selbstau) </option>
            </select>

            <input
                  className="input"
                  placeholder="Stückzahl"
                  name="stk"
                  type="number"
                />

             
      
                <textarea
                className="input5"
                placeholder="Zusätzliche Informationen"
                type="text"
                name="message"
                />
    
                <div className="checkbox_container">
                  <input
                    type="checkbox"
                    id="kontakt"
                    name="kontakt"
                    required
                  />
                  <label for="kontakt">
                    Ich akzeptiere die Datenschutzbestimmungen
                  </label>
                </div>
                <button type="submit" value="send">Absenden</button>
              </form>
            </div>
      );
    }
    
    export default KontaktForm;