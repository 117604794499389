import React from 'react';
import "./TopPic.css";

function TopPic() {
    return (
        <div className="top_pic"> 
            <h1>Kontakt</h1>
        </div>
    )
}

export default TopPic;
