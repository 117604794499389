import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Ueberzogene() {
    return (
        <div className="produkt_info">
               <Helmet>
            <title>Überzogene Kartonagen - Peter Weber Kartonagen</title>
            <meta name="description" content="In Handarbeit liebevoll und mit großer Sorgfalt hergestellt sind diese Kartonagen vielseitig verwendbar und werden vor allem gerne für exquisite Produkte (z.B. Bonbonieren, Souvenirs, feine Textilien) genützt." />
        </Helmet>
            <div className="produkt_page">
 <h1>Überzogene Kartonagen</h1>
<hr />
 <p>In Handarbeit liebevoll und mit großer Sorgfalt hergestellt sind diese Kartonagen vielseitig verwendbar und werden vor allem gerne für exquisite Produkte (z.B. Bonbonieren, Souvenirs, feine Textilien) genützt.</p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>

<ProduktInfo 
info= "Silber- und Golddruck "
/>


<div className="produkt_fotos">
<img src="./img/Ueberzogene_Kartons_3.jpg" alt="Ueberzogene Kartonagen" />
<img src="./img/Ueberzogene_Kartons_2.jpg" alt="Ueberzogene Kartonagen" />




</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Überzogene-Kartonagen" />
            </div>

        </div>
    )
}

export default Ueberzogene
