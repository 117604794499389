import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Luxuskartonagen() {
    return (
        <div className="produkt_info">
                   <Helmet>
            <title>Luxuskartonagen - Peter Weber Kartonagen</title>
            <meta name="description" content="Hier können Sie ihre Verpackung zu 100% selbst gestalten. Gerne hilft Ihnen unser Team bei der Umsetzung und Konzeption. Jetzt Anfrage senden!" />
        </Helmet>
            <div className="produkt_page">
 <h1>Luxuskartonagen</h1>
<hr />
 <p>Bei unseren Luxuskartonagen sind ihrer Kreativität keine Grenzen gesetzt. Wir haben bereits für namhafte Marken edle Kartonagen hergestellt, dessen Qualität seinesgleichen sucht. </p>

 <ProduktInfo 
info= "Handgemacht in 1100 Wien"
/>

<ProduktInfo 
info= "Bestellung ab 100 Stk"
/>


<ProduktInfo 
info= "Verschiedene Konstruktionen Möglich"
/>



<div className="produkt_fotos">
<img src="./img/Luxuskartonagen.jpg" alt="info" />
 <img src="./img/Schwarzes_Kamel.jpg" alt="info" />
 <img src="./img/Altmann_Kuehne.jpg" alt="info" />
 <img src="./img/Wiener_Bonbons.jpg" alt="info" />

</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Luxuskartonagen" />
            </div>

        </div>
    )
}

export default Luxuskartonagen
