import React from 'react';
import "./Produkt.css";
import ProduktKontakt from "./ProduktKontakt.js";
import ProduktCta from "./ProduktCta.js";
import ProduktInfo from "./ProduktInfo.js";
import {Helmet} from "react-helmet";

function Faltkartons() {
    return (
        <div className="produkt_info">
                    <Helmet>
            <title>Faltkartons - Peter Weber Kartonagen </title>
            <meta name="description" content="Von ganz klein bis zum großen Übersiedlungskarton sind der Vielfalt hier keine Grenzen gesetzt. Erhältlich in diversen Wellpappesorten, gestanzt, geklebt oder geheftet." />
        </Helmet>
            <div className="produkt_page">
 <h1>Faltkartons</h1>
<hr />
 <p>Von ganz klein bis zum großen Übersiedlungskarton sind der Vielfalt hier keine Grenzen gesetzt. Erhältlich in diversen Wellpappesorten, gestanzt, geklebt oder geheftet.</p>

 <ProduktInfo 
info= "Handgefertigt in 1100 Wien"
/>

<ProduktInfo 
info= "Verschiedene Größen verfügbar"
/>

<ProduktInfo 
info= "Diverse Wellpappesorten "
/>

<ProduktInfo 
info= "Gestanzt, geklebt oder geheftet"
/>




<div className="produkt_fotos">
<img src="./img/Peter_Weber.jpg" alt="info" />


</div>
<ProduktCta />


            </div>
            <div className="produkt_kontakt">
        
            <ProduktKontakt 
            Produkt="Faltkartons" />
            </div>

        </div>
    )
}

export default Faltkartons
