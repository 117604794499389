import React, { useState } from 'react';
import './ProduktKontakt.css';
import emailjs from 'emailjs-com';

function KontaktForm({submitForm}) {

    const [Submitted, setSubmitted] = useState(false)
    
      function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm( 'service_jixa5pm', 'template_yv6de9b', e.target, 'user_Vj1X1aY6fIOlEKY6msVA9')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          setSubmitted(true);
          submitForm();

    
      }
    
      return (
   
            <div className="formular">
                
              <form
                onSubmit={sendEmail}
                id="contact-form"
                name="contact-form"
              >
                <input
                  className="input"
                  placeholder="Vor & Nachname*"
                  name="name"
                  type="text"
                  required
                />
                <input
                  className="input"
                  placeholder="E-Mail-Adresse*"
                  type="email"
                  name="email"
                  required
                />

                  <input
                  className="input"
                  placeholder="Telefonnummer"
                  name="stk"
                  type="text"
                />

            <select
              className="input3"
              name="produkt"
              required
            >
              <option value="Nichts Ausgewählt"> Produkt Auswählen </option>
              <option value="Faltkartons"> Faltkartons </option>
              <option value="Rohe Kartonagen"> Rohe Kartonagen </option>
              <option value="Überzogene Kartonagen"> Überzogene Kartonagen </option>
              <option value="Luxuskartonagen"> Luxuskartonagen </option>
              <option value="Diplomrollen"> Diplomrollen </option>
              <option value="Klarsicht-Verpackungen"> Klarsicht-Verpackungen </option>
              <option value="Aktenkartons"> Aktenkartons </option>
              <option value="Schulschachteln"> Schulschachteln </option>
              <option value="Teebäckerei Kartons"> Teebäckerei Kartons </option>
              <option value="Spulen"> Spulen </option>
              <option value="Sonstiges"> Sonstiges </option>


            </select>
             
               
                 {/* <input
                  className="input_6"
                  placeholder="Stückzahl"
                  name="stk"
                  type="text11"
                /> */}
                
      
                <textarea
                className="input5"
                placeholder="Wie können wir Ihnen weiterhelfen?"
                type="text"
                name="message"
                />



    
    
                <div className="checkbox_container">
                  <input
                    type="checkbox"
                    id="kontakt"
                    name="kontakt"
                    required
                  />
                  <label for="kontakt">
                    Ich akzeptiere die Datenschutzbestimmungen
                  </label>
                </div>
                <button type="submit" value="send">Absenden</button>
              </form>
            </div>
      );
    }
    
    export default KontaktForm;