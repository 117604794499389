import React from 'react';
import "./HomeFeatures.css";
import { Container } from 'react-bootstrap';


function HomeFeatures2() {
    return (
        <div className="home_features">

            <div className="home_features_top">
            <h2>Unser großer Auftritt im ORF </h2>
            <hr />
            <iframe className="youtube_video" width="750" height="440" src="https://www.youtube.com/embed/loIC01lvPNM?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

        </div>
    )
}

export default HomeFeatures2
